import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {DataService} from '@app/core/service/data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private dataService: DataService, private router: Router) {
  }

  canActivate(): boolean {
    if (!this.dataService.getUserToken()) {
      this.redirectLogin();
      return false;
    }
    return true;
  }

  /**
   * @author Praveen Prasad
   * @description redirection to login page if there is no token
   * @memberof AuthGuard
   */
  redirectLogin() {
    localStorage.clear();
    this.router.navigate(['/user-authentication/login']);
  }
}
