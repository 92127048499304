import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public isLogoClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor() {
  }

  setLogoClick(isClicked) {
    this.isLogoClicked.next(isClicked);
  }

  getLogoClickSubscription() {
    return this.isLogoClicked.asObservable();
  }
}
