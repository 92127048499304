import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TagManagementService {
  constructor(private http: HttpClient) {
  }

  getTagList(searchInput?: string) {
    return this.http.get(
      `tags/listing?limit=1000&skip=0${
        searchInput ? `&search=${searchInput}` : ''
      }`
    );
  }

  getTaggedImages(limit: number, skip: number, tagIdArray: any) {
    return this.http.post(
      `files/tag-file-listing?limit=${limit}&skip=${skip}&orderBy=createdAt asc`,
      tagIdArray
    );
  }

  createTag(tagData: any) {
    return this.http.post(`tags`, tagData);
  }

  editTag(tagData: any) {
    return this.http.patch(
      `componentTagMappings/edit-components-tag-mapping`,
      tagData
    );
  }

  deleteTag(tagId: string) {
    return this.http.delete(
      `componentTagMappings/delete-components-tag-mapping/${tagId}`
    );
  }

  getTaggedLocations(tagId: string) {
    return this.http.get(
      `tagFileMappings/tag-based-location-listing?tagId=${tagId}`
    );
  }

  getComponentTags(limit?: number, skip?: number, componentId?: string) {
    return this.http.get(
      `tags/component-mapped-listing?limit=${limit}&skip=${skip}&componentId=${componentId}`
    );
  }

  getComponentTagsByImgTag(limit?: number, skip?: number, componentId?: string, isImgTab?: boolean) {
    return this.http.get(
      `tags/component-mapped-listing?limit=${limit}&skip=${skip}&componentId=${componentId}&isImgTab=${isImgTab}`
    );
  }
}
