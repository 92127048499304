import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {SharedModule} from '@app/shared/shared.module';
import {CoreModule} from '@app/core/core.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from '@app/app-routing.module';
import {DataService} from '@app/core/service/data.service';
import {ToastrModule} from 'ngx-toastr';
import {ThemeService} from 'ng2-charts';
import {NgxEchartsModule} from 'ngx-echarts';

export function init_app(dataService: DataService) {
  return () => dataService.setUserDetails();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [DataService],
      multi: true,
    },
    ThemeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
