import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

/** Constant Values */
import {environment} from '@env/environment';
import {DataService} from '../service/data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  API_URL = environment.baseurl;
  accessToken: string;
  requestHeaders;

  constructor(private router: Router, private dataService: DataService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    this.requestHeaders = {
      contentType: 'application/json',
    };
    const token = localStorage.getItem('accessToken');

    // Attach access token with each request
    if (token) {
      headersConfig['Authorization'] = `${token}`;
    }

    request = request.clone({setHeaders: headersConfig});

    if (request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.delete('Content-Type'),
      });
    } else {
      request = request.clone({
        headers: request.headers.set(
          'Content-Type',
          this.requestHeaders.contentType
        ),
      });
    }
    // Attach base url to each request
    request = request.clone({
      url: this.API_URL + request.url,
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do nothing for now
        }
        return event;
      }),

      catchError((error: HttpErrorResponse) => {
        const status = error.status;
        var message = error && error.error.message ? error.error.message : '';
        if (status === 401) {
          if (this.router.url !== '/user-authentication') {
            this.dataService.manageLogout();
            localStorage.clear();

            this.router.navigate(['user-authentication']);
          }
        } else if (status === 500) {
          message = 'Something went wrong';
        }
        this.handleError(status, message);
        return throwError(error);
      })
    );
  }

  handleError(status, message) {
    if (status === 400) {
      // Toast `$message`
    } else {
      // Toast 'Something went wrong'
    }
  }
}
