import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ComponentManagementService {
  constructor(private http: HttpClient) {
  }

  /**
   * @author Praveen Prasad
   * @description API call to get the component list
   * @returns
   * @memberof ComponentManagementService
   */
  getComponentList(): Observable<any[]> {
    const filter = {
      where: {isDeleted: {inq: ['false']}},
      order: 'priority  ASC',
    };
    const filterParams = JSON.stringify(filter);

    return this.http.get<any[]>(`components?filter=${filterParams}`);
  }

  getSubComponentList(
    limit?: number,
    skip?: number,
    orderBy?: string,
    componentId?: any,
    failedSubComponent?: any
  ) {
    return this.http.get(
      `failedSubComponents/failed-sub-component-listing?limit=${limit}&skip=${skip}&orderBy=asc${
        componentId ? `&componentId=${componentId}` : ''
      }${
        failedSubComponent ? `&failedSubComponent=${failedSubComponent}` : ''
      } `
    );
  }

  getFilteredSubComponentList(
    limit?: number,
    skip?: number,
    orderBy?: string,
    params?: any
  ) {
    return this.http.get(
      `failedSubComponents/failed-sub-component-listing?limit=${limit}&skip=${skip}&orderBy=desc`,
      {params: params}
    );
  }

  createMainComponent(name: string): Observable<void> {
    return this.http.post<void>('create-main-component', {name});
  }

  editMainComponent(id: string, name: string): Observable<void> {
    return this.http.put<void>(`update-main-component/${id}`, {name});
  }

  deleteMainComponent(id: string): Observable<void> {
    return this.http.delete<void>(`delete-main-component/${id}`);
  }

  createSubComponent(subComponentDetails: any) {
    return this.http.post(`failedSubComponents`, subComponentDetails);
  }

  editSubComponent(editedData) {
    return this.http.patch(`failedSubComponents`, editedData);
  }

  deleteSubComponent(deletedSubComponent: any) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: deletedSubComponent,
    };
    return this.http.delete(
      `failedSubComponents/failed-sub-components`,
      httpOptions
    );
  }

  /**
   * @author Praveen Prasad
   * @description API call during re-arranging components
   * @param {*} componentsList
   * @returns
   * @memberof ComponentManagementService
   */
  reorderComponents(componentsList: any) {
    return this.http.post(`components/edit-components`, componentsList);
  }

  /**
   * @author Praveen Prasad
   * @description API call to create a new component
   * @param {*} componentDetails
   * @returns
   * @memberof ComponentManagementService
   */
  createComponent(componentDetails: any) {
    return this.http.post(`components`, componentDetails);
  }

  /**
   * @author Praveen Prasad
   * @description API call to delete component
   * @param {string} id
   * @returns
   * @memberof ComponentManagementService
   */
  deleteComponent(id: string) {
    return this.http.delete(`components/${id}`);
  }

  /**
   * @author Praveen Prasad
   * @description API call to edit component details
   * @param {*} editedData
   * @memberof ComponentManagementService
   */
  editComponents(editedData: any) {
    return this.http.post(`components/edit-components`, editedData);
  }

  getMainComponentList(): Observable<any> {
    return this.http.get('components-list');
  }
}
