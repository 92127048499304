<app-sidebar [show]="isAuthenticated && !resetPass"></app-sidebar>
<app-header *ngIf="userInfo && !resetPass"></app-header>
<div
  [ngClass]="{
    'layout-content': userInfo && !resetPass,
    'scroll-bar': router?.url !== '/reports/create-new-report',
    'no-scroll-bar': router?.url === '/reports/create-new-report'
  }"
  style="min-height: 88% !important"
>
  <router-outlet></router-outlet>
</div>
<app-footer *ngIf="userInfo && data?.id && !resetPass"></app-footer>
<app-loader></app-loader>
