import {UserManagementService} from './../../core/service/user-management.service';
import {AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation,} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '@app/core/service/data.service';
import {SidebarService} from '@app/core/service/sidebar.service';
import {
  FailureAnalysisManagementLocalService
} from '@app/feature/failure-analysis/failure-analysis-list/failure-analysis-management.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, AfterContentChecked {
  role: string;
  @Input('show') visible: any;
  menuItemList = [];
  subMenuIndex: any;
  navbarCollapse: boolean;
  oneAtATime: boolean;
  userFolderDetails: any = [];

  constructor(
    private cdref: ChangeDetectorRef,
    private router: Router,
    private dataService: DataService,
    private userService: UserManagementService,
    private famLocalService: FailureAnalysisManagementLocalService,
    private sidebarService: SidebarService
  ) {
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('User'));
    this.navbarCollapse = true;
    this.oneAtATime = true;
    this.dataService.getUserRole().subscribe((x) => {
      this.role = x;
      if (this.role === 'consultant') {
        this.menuItemList = [
          {
            id: 'analytics',
            isOpen: false,
            title: 'OVERVIEW',
            alt: 'OVERVIEW',
            img: 'view-dashboard',
            link: '/analytics',
            linkActive: 'link-active',
          },
          {
            id: 'reports',
            title: 'REPORTS',
            link: '/reports',
            linkActive: 'link-active',
            alt: 'REPORTS',
            isOpen: true,
            img: 'report',
            children:  user?.reportApproval ? [
              {
                id: 'approval',
                link: '/reports',
                linkActive: 'link-active',
                route: 'approval-report',
                title: 'Approval reports',
              }
              ] : null
          },
          {
            id: 'calendar',
            isOpen: false,
            title: 'CALENDAR',
            alt: 'calendar',
            img: 'calendar',
            link: '/calendar',
            linkActive: 'link-active',
          },
        ];
      } else if (this.role === 'esp supplier') {
        this.menuItemList = [
          {
            id: 'analytics',
            isOpen: false,
            title: 'OVERVIEW',
            alt: 'OVERVIEW',
            img: 'view-dashboard',
            link: '/analytics',
            linkActive: 'link-active',
          },
          {
            id: 'reports',
            title: 'REPORTS',
            link: '/reports',
            linkActive: 'link-active',
            alt: 'REPORTS',
            isOpen: false,
            img: 'report',
          },
        ];
      } else if (this.role === 'operator') {
        const details = JSON.parse(localStorage.getItem('userDetails'));
        this.userService.getUserDetails(details.userId, '').subscribe(
          (resp) => {
            this.userFolderDetails = resp.operatorFolderDetails;
            this.famLocalService.nameOfSelectedOperator = resp.name;
            this.famLocalService.nameOfSelectedWell = '';
            this.famLocalService.nameOfSelectedComponent = '';
            this.famLocalService.nameOfSelectedImage = '';
            this.famLocalService.organizationId = resp.id;
            if (this.userFolderDetails.length > 0) {
              this.menuItemList = [
                {
                  id: 'analytics',
                  isOpen: false,
                  title: 'OVERVIEW',
                  alt: 'OVERVIEW',
                  img: 'view-dashboard',
                  link: '/analytics',
                  linkActive: 'link-active',
                },
                {
                  id: 'failure',
                  isOpen: false,
                  title: 'OPERATOR AND WELL MANAGEMENT',
                  alt: 'OPERATOR AND WELL MANAGEMENT',
                  img: 'folder',
                  link: '/failure-analysis/' + this.userFolderDetails[0].id,
                  linkActive: 'link-active',
                },
                {
                  id: 'reports',
                  title: 'REPORTS',
                  link: '/reports',
                  linkActive: 'link-active',
                  alt: 'REPORTS',
                  isOpen: false,
                  img: 'report',
                },
                {
                  id: 'map-view',
                  title: 'MAP VIEW',
                  link: '/map-view',
                  linkActive: 'link-active',
                  alt: 'MAP VIEW',
                  isOpen: false,
                  img: 'marker',
                },
              ];
            } else {
              this.menuItemList = [
                {
                  id: 'analytics',
                  isOpen: false,
                  title: 'OVERVIEW',
                  alt: 'OVERVIEW',
                  img: 'view-dashboard',
                  link: '/analytics',
                  linkActive: 'link-active',
                },
                {
                  id: 'failure',
                  isOpen: false,
                  title: 'OPERATOR & WELL MANAGEMENT',
                  alt: 'OPERATOR & WELL MANAGEMENT',
                  img: 'folder',
                  link: '/dashboard',
                  linkActive: 'link-active',
                },
                {
                  id: 'equipment',
                  title: 'EQUIPMENT DESCRIPTION',
                  link: '/equipment-description',
                  linkActive: 'link-active',
                  alt: 'EQUIPMENT DESCRIPTION',
                  isOpen: false,
                  img: 'equipment-description',
                },
                {
                  id: 'reports',
                  title: 'REPORTS',
                  link: '/reports',
                  linkActive: 'link-active',
                  alt: 'REPORTS',
                  isOpen: false,
                  img: 'report',
                },
                {
                  id: 'image-search',
                  title: 'IMAGE SEARCH',
                  link: '/image-search',
                  linkActive: 'link-active',
                  alt: 'IMAGE SEARCH',
                  isOpen: false,
                  img: 'img-search',
                },
                {
                  id: 'map-view',
                  title: 'MAP VIEW',
                  link: '/map-view',
                  linkActive: 'link-active',
                  alt: 'MAP VIEW',
                  isOpen: false,
                  img: 'marker',
                },
              ];
            }
          },
          (err) => {
            // this.toastrService.error(toastrMessages.userLoadError, toastrMessages.errorMsg, {
            //   timeOut: 3000
            // });
          }
        );
      } else if (this.role !== null && this.role !== undefined) {
        this.menuItemList = [
          {
            id: 'analytics',
            isOpen: false,
            title: 'OVERVIEW',
            alt: 'OVERVIEW',
            img: 'pie-chart',
            link: '/analytics',
            linkActive: 'link-active',
          },
          {
            id: 'control-panel',
            isOpen: false,
            title: 'DASHBOARD',
            alt: 'DASHBOARD',
            img: 'view-dashboard',
            link: '/control-panel',
            linkActive: 'link-active',
          },
          {
            id: 'usrid',
            isOpen: true,
            title: 'USER MANAGEMENT',
            alt: 'USER MANAGEMENT',
            img: 'map',
            link: '/user-management',
            linkActive: 'link-active',
            children: [
              {
                id: 'conid',
                link: '/user-management',
                linkActive: 'link-active',
                route: 'consultant',
                title: 'CONSULTANT',
              },
              {
                id: 'opid',
                link: '/user-management',
                linkActive: 'link-active',
                route: 'operator',
                title: 'OPERATOR',
              },
              {
                id: 'supid',
                link: '/user-management',
                linkActive: 'link-active',
                route: 'supplier',
                title: 'ESP SUPPLIER',
              },
              {
                id: 'suplist',
                link: '/user-management',
                linkActive: 'link-active',
                route: 'supplierslist',
                title: 'THIRD PARTY SUPPLIER',
              },
            ],
          },
          {
            id: 'failure',
            isOpen: false,
            title: 'OPERATOR AND WELL MANAGEMENT',
            alt: 'OPERATOR AND WELL MANAGEMENT',
            img: 'folder',
            link: '/failure-analysis',
            linkActive: 'link-active',
          },
          {
            id: 'cmpid',
            isOpen: false,
            title: 'COMPONENT MANAGEMENT',
            alt: 'COMPONENT MANAGEMENT',
            img: 'sub-category',
            link: '/components',
            linkActive: 'link-active',
          },
          {
            id: 'equipment',
            title: 'EQUIPMENT DESCRIPTION',
            link: '/equipment-description',
            linkActive: 'link-active',
            alt: 'EQUIPMENT DESCRIPTION',
            isOpen: false,
            img: 'equipment-description',
          },
          {
            id: 'wellData',
            title: 'WELL DATA',
            link: '/well-data',
            linkActive: 'link-active',
            alt: 'WELL DATA',
            isOpen: false,
            img: 'vectorwell',
          },

          {
            id: 'additlib',
            isOpen: false,
            title: 'ROOT CAUSE',
            alt: 'ROOT CAUSE',
            img: 'library',
            link: '/additional-libraries',
            linkActive: 'link-active',
          },
          {
            id: 'tag',
            title: 'TAG MANAGEMENT',
            link: '/tag-management',
            linkActive: 'link-active',
            alt: 'TAG MANAGEMENT',
            isOpen: false,
            img: 'tag',
          },
          {
            id: 'reports',
            title: 'REPORTS',
            link: '/reports',
            linkActive: 'link-active',
            alt: 'REPORTS',
            isOpen: false,
            img: 'report',
          },
          {
            id: 'image-search',
            title: 'IMAGE SEARCH',
            link: '/image-search',
            linkActive: 'link-active',
            alt: 'IMAGE SEARCH',
            isOpen: false,
            img: 'img-search',
          },
          {
            id: 'map-view',
            title: 'MAP VIEW',
            link: '/map-view',
            linkActive: 'link-active',
            alt: 'MAP VIEW',
            isOpen: false,
            img: 'marker',
          },
          {
            id: 'trash',
            title: 'TRASH BIN',
            link: '/trashbin',
            linkActive: 'link-active',
            alt: 'TRASH BIN',
            isOpen: false,
            img: 'trash',
          },
          {
            id: 'calendar',
            isOpen: false,
            title: 'CALENDAR',
            alt: 'calendar',
            img: 'calendar',
            link: '/calendar',
            linkActive: 'link-active',
          },
        ];
      }
    });
    this.subscribeToLogoClick();
  }

  subscribeToLogoClick() {
    this.sidebarService
      .getLogoClickSubscription()
      .subscribe((isLogoClicked) => {
        if (isLogoClicked) {
          this.menuItemList.forEach((item, i) => {
            this.menuItemList[i].active = false;
            if (this.role === 'admin') {
              if (i === 1) {
                this.menuItemList[1].children[0].active = false;
                this.menuItemList[1].children[1].active = false;
                this.menuItemList[1].children[2].active = false;
                this.menuItemList[1].children[3].active = false;
                this.menuItemList[1].children[4].active = false;
              }
            }
          });
        }
      });
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
    const activeIndex = this.menuItemList.findIndex(
      (menu) => menu.link === this.router.url
    );
    if (activeIndex >= 0) {
      this.menuItemList[activeIndex].active = true;
    }
  }

  addId(event: any, index: number) {
    this.menuItemList.forEach((item, i: number): void => {
      if (index === i) {
        this.menuItemList[index].active = true;
        if (this.role === 'admin') {
          if (index === 1) {
            this.menuItemList[1].children[0].active = true;
            this.menuItemList[1].children[1].active = false;
            this.menuItemList[1].children[2].active = false;
            this.menuItemList[1].children[3].active = false;
          }
        }
      } else {
        this.menuItemList[i].active = false;
      }
    });
  }

  checkRoute(event: { stopPropagation: () => void; }, link: any, value = '', i: string | number, j: string | number): void {
    event.stopPropagation();
    this.subMenuIndex = j;
    this.router.navigate([link, value]);
    this.menuItemList[i].children.forEach((item: any, k: string | number) => {
      if (k === j) {
        this.menuItemList[i].children[j].active = true;
      } else {
        this.menuItemList[i].children[k].active = false;
      }
    });
  }
}
