import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '@app/core/service/http-request/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '@app/core/service/data.service';
import {ToastrService} from 'ngx-toastr';
import {PASSWORDREGX, toastrMessages} from '@app/util/app-constants';
import {takeWhile} from 'rxjs/operators';
import {UserManagementService} from '@app/core/service/user-management.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changePassForm: UntypedFormGroup;
  bsModalRef: BsModalRef;
  passwordMatch: boolean;
  visiblePassword = false;
  visiblePassword2 = false;
  passwordRegx = PASSWORDREGX;
  accessToken: string;
  _Alive = true;

  constructor(
    private fb: UntypedFormBuilder,
    private httpAuth: AuthService,
    private router: Router,
    private userService: UserManagementService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private modalService: BsModalService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.dataService.resetPassDisplay();

    this.changePassForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.passwordRegx),
          ]),
        ],
        cpassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.passwordRegx),
          ]),
        ],
      },
      {validator: this.checkIfMatchingPasswords('password', 'cpassword')}
    );

    this.route.queryParams.subscribe((params) => {
      this.accessToken = params['access_token'];
    });
  }

  ngOnDestroy() {
    this._Alive = false;
    this.dataService.resetPassNoDisplay();
  }

  onSubmitPass() {
    if (
      this.changePassForm.value.password === this.changePassForm.value.cpassword
    ) {
      this.passwordMatch = true;
    } else {
      this.passwordMatch = false;
      this.toastrService.error(
        toastrMessages.confirmPasswordError,
        toastrMessages.errorMsg,
        {
          timeOut: 3000,
        }
      );
    }
    if (this.passwordMatch) {
      const resetPassData = {
        newPassword: this.changePassForm.value.password,
      };
      this.httpAuth
        .resetPassword(resetPassData, this.accessToken)
        .pipe(takeWhile(() => this._Alive))
        .subscribe(
          (resp) => {
            this.toastrService.success(
              toastrMessages.changePasswordSuccess,
              toastrMessages.successMsg,
              {
                timeOut: 5000,
              }
            );
            this.dataService.purgeAuth();
            this.dataService.manageLogout();
            this.router.navigate(['/user-authentication/login']);
          },
          (err) => {
            this.toastrService.error(
              toastrMessages.changePasswordError,
              toastrMessages.errorMsg,
              {
                timeOut: 3000,
              }
            );
          }
        );
    }
  }

  private checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: UntypedFormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
}
