<p-menubar>
  <div class="header d-flex layerone">
    <div class="nav-item cursor-pointer mobile-logo" placement="left"></div>
    <div class="mr-auto">
      <img
        (click)="navDashboard()"
        class="img-size cursor-pointer"
        src="assets/images/DCL-logo-text.png"
      />
    </div>

    <div class="d-flex login-item">
      <div class="mt-3 pt-1 mr-1 header-dropdown-width font-group no-events">
        Welcome, <b> {{ userName }} </b>
      </div>

      <div class="logout-box d-flex align-items-center justify-content-center">
        <img
          (click)="userLogout()"
          class="logout-dropdown"
          src="assets/images/logout_icon.svg"
          title="Click to Logout"
        />
      </div>
    </div>
  </div>
</p-menubar>
<p-dialog
  [(visible)]="logoutModal"
  [contentStyle]="{ overflow: 'visible' }"
  [draggable]="false"
  [modal]="true"
  [responsive]="true"
  [styleClass]="'dialog-style2'"
  class="p-confirm-btn"
  header="Logout"
  showEffect="fade"
>
  <div class="w-100">
    <div class="mt-1">
      <span class="del-text-size">Are you sure you want to Logout?</span>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane span.p-button-text.p-clickable pb-4">
      <button
        (click)="logoutUser()"
        class="not-allowed btn-red px-2 py-1 mr-4 delete-btn-width shadow-none"
        pButton
        type="button"
      >
        Yes
      </button>
      <button
        (click)="logoutModal = false"
        class="not-allowed cus-input btn-white px-2 py-1 shadow-none delete-btn-width"
        pButton
        type="button"
      >
        No
      </button>
    </div>
  </ng-template>
</p-dialog>
