import {TrashbinService} from './service/trashbin.service';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataService} from './service/data.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './http_interceptor/auth-interceptor';
import {AuthGuard} from './guards/auth-guard.service';
import {NoAuthGuard} from './guards/no-auth-guard.service';
import {UserManagementService} from './service/user-management.service';
import {LoaderService} from './service/loader.service';
import {LoaderInterceptor} from './http_interceptor/loader-interceptor';
import {ComponentManagementService} from '@app/core/service/component-management.service';
import {TagManagementService} from './service/tag-management.service';
import {HighlightSearchTermPipe} from './pipes/highlight-search-term.pipe';
import {HttpErrorInterceptor} from '@app/core/http_interceptor/http-error.interceptor';

@NgModule({
  declarations: [HighlightSearchTermPipe],
  imports: [CommonModule],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    DataService,
    LoaderService,
    AuthGuard,
    NoAuthGuard,
    UserManagementService,
    ComponentManagementService,
    TagManagementService,
    TrashbinService,
  ],
  exports: [HighlightSearchTermPipe],
})
export class CoreModule {
}
