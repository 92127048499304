<p>Are you sure you want to delete {{name}}?</p>

<div class="pl-3 pr-3 pb-3">
  <button
    (click)="cancel()"
    class="cursor-pointer cancel cus-input btn-white shadow-none"
    pButton
    type="button">
    Cancel
  </button>
  <button
    (click)="onSubmit()"
    class="not-allowed btn-red mr-3"
    pButton
    type="button">
    Delete
  </button>
</div>
