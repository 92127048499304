<p-card [styleClass]="'card'">
  <ng-template class="position-sticky" pTemplate="header">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-content-center">
        <span class="">
          <img
            class="header-img-allign"
            src="assets/images/fam-card/gray-folder.svg"
          />
        </span>

        <span
          (click)="save(data.id)"
          *ngIf="data?.title?.length > 30"
          class="align-self-start font-weight-bold card-header-name"
          title="{{ data.title }}"
        >
          {{ data.title | slice : 0 : 30 }}...
        </span>

        <span
          (click)="save(data.id)"
          *ngIf="data?.title?.length <= 30"
          class="align-self-start font-weight-bold card-header-name"
        >
          {{ data.title }}
        </span>
        <span class="arrow-align">
          <img
            (click)="save(data.id)"
            class="folder-icn cursor-pointer"
            src="assets/images/icons8-circled-right-64.png"
            title="Manage Well(s)"
          />
        </span>
      </div>
      <div class="d-flex align-content-center">
        <span *ngIf="hasOptions">
          <div class="btn-group pull-right dots-align" dropdown>
            <button
              aria-controls="dropdown-basic"
              class="btn dropdown-toggle"
              dropdownToggle
              id="button-basic"
              type="button"
            >
              <div class="">
                <div class="dot spacing"></div>
                <div class="dot spacing"></div>
                <div class="dot"></div>
              </div>
            </button>

            <ul
              *dropdownMenu
              aria-labelledby="button-basic"
              class="dropdown-menu fam-crd cursor-pointer"
              id="dropdown-basic"
              role="menu"
            >
              <li role="menuitem">
                <a
                  (click)="openInfoModalComponent(data.id)"
                  class="dropdown-item"
                >
                  <img
                    class="drop-icons-allign"
                    src="assets/images/information.svg"
                  />
                  Info</a
                >
              </li>
              <li role="menuitem">
                <a
                  (click)="openEditModalComponent(data.id)"
                  class="dropdown-item"
                >
                  <img
                    class="drop-icons-allign"
                    src="assets/images/edit_icon.svg"
                  />
                  Edit</a
                >
              </li>
              <li role="menuitem">
                <a
                  (click)="deleteFolder(data.id, data.organizationId)"
                  class="dropdown-item"
                >
                  <img
                    class="drop-icons-allign"
                    src="assets/images/garbage.svg"
                  />
                  Delete</a
                >
              </li>
            </ul>
          </div>
        </span>
      </div>
    </div>
  </ng-template>
  <div class="tree-area">
    <p-tree
      (onNodeExpand)="nodeSelect($event)"
      [(selection)]="selectedFile"
      [value]="treeData"
      selectionMode="single"
    ></p-tree>
  </div>
</p-card>
