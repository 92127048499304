import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';
import {DataService} from '@app/core/service/data.service';

@Injectable({
  providedIn: 'root',
})
export class NoConsultantGuard
  implements CanActivate, CanActivateChild, CanLoad {
  role: string;

  constructor(private dataService: DataService, private route: Router) {
    this.dataService.getUserRole().subscribe((x) => {
      this.role = x;
    });
  }

  fn() {
    if (this.role !== 'consultant') {
      return true;
    } else {
      this.route.navigate(['/reports']);
      return false;
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.fn();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.fn();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.fn();
  }
}
