<div class="wrapper">
  <div *ngIf="!userLogged">
    <button (click)="loginToApp()" class="btn btn-custom-red">Login</button>
  </div>
  <div class="header-wrapper">
    <div class="title">Preview Report</div>
  </div>
  <div class="body-wrapper">
    <pdf-viewer
      [autoresize]="true"
      [fit-to-page]="false"
      [original-size]="false"
      [render-text]="true"
      [rotation]="0"
      [show-all]="true"
      [show-borders]="false"
      [src]="urlPdf"
      [stick-to-page]="false"
      [zoom-scale]="'page-width'"
      [zoom]="1"
      class="view-pdf"
    ></pdf-viewer>
  </div>
</div>
