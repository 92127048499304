// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseurl: 'https://difa-app.com/api/',
 // baseurl: "http://localhost:3000/api/",
  api: {
    login: 'users/login',
    logout: 'users/logout',
    createUser: 'users',
    resetRequest: 'users/reset',
    resetPassword: 'users/reset-password',
    changePassword: 'users/change-password',
  },
};

/*
 * For easier  ing in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
