import {NoOperatorGuardService} from './core/guards/no-operator-guard.service';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth-guard.service';
import {NoAuthGuard} from './core/guards/no-auth-guard.service';
import {NoConsultantGuard} from './core/guards/no-consultant.guard';
import {ChangePasswordComponent} from './shared/change-password/change-password.component';
import {PreviewReportForClientComponent} from './feature/reports/preview-report-for-client/preview-report-for-client.component';

const routes: Routes = [
  {path: '', redirectTo: 'user-authentication', pathMatch: 'full'},
  {
    path: 'user-authentication',
    loadChildren: () =>
      import('./feature/User-Authentication/user-authentication.module').then(
        (m) => m.UserAuthenticationModule
      ),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'components',
    loadChildren: () =>
      import(
        './feature/components-management/components-management.module'
        ).then((m) => m.ComponentsManagementModule),
    canActivate: [AuthGuard, NoConsultantGuard, NoOperatorGuardService],
    canLoad: [NoConsultantGuard, NoOperatorGuardService],
  },
  {
    path: 'failure-analysis',
    loadChildren: () =>
      import('./feature/failure-analysis/failure-analysis.module').then(
        (m) => m.FailureAnalysisModule
      ),
    canActivate: [AuthGuard, NoConsultantGuard],
    canLoad: [NoConsultantGuard],
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./feature/calendar/calendar.module').then(
        (m) => m.CalendarModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'equipment-description',
    loadChildren: () =>
      import(
        './feature/equipment-description/equipment-description.module'
        ).then((m) => m.EquipmentDescriptionModule),
    canActivate: [AuthGuard, NoConsultantGuard],
    canLoad: [NoConsultantGuard],
  },
  {
    path: 'well-data',
    loadChildren: () =>
      import('./feature/well-data/well-data.module').then(
        (m) => m.WellDataModule
      ),
    canActivate: [AuthGuard, NoConsultantGuard, NoOperatorGuardService],
    canLoad: [NoConsultantGuard, NoOperatorGuardService],
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./feature/analytics/analytics.module').then(
        (m) => m.AnalyticsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    loadChildren: () =>
      import('./feature/user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
    canActivate: [AuthGuard, NoConsultantGuard, NoOperatorGuardService],
    canLoad: [NoConsultantGuard, NoOperatorGuardService],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./feature/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'additional-libraries',
    loadChildren: () =>
      import('./feature/additional-libraries/additional-libraries.module').then(
        (m) => m.AdditionalLibraryModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'trashbin',
    loadChildren: () =>
      import('./feature/trashbin/trashbin.module').then(
        (m) => m.TrashbinModule
      ),
    canActivate: [AuthGuard, NoConsultantGuard, NoOperatorGuardService],
    canLoad: [NoConsultantGuard, NoOperatorGuardService],
  },
  {
    path: 'image-search',
    loadChildren: () =>
      import('./feature/image-search/image-search.module').then(
        (m) => m.ImageSearchModule
      ),
    canActivate: [AuthGuard, NoConsultantGuard],
    canLoad: [NoConsultantGuard],
  },
  {
    path: 'tag-management',
    loadChildren: () =>
      import('./feature/tag-management/tag-management.module').then(
        (m) => m.TagManagementModule
      ),
    canActivate: [AuthGuard, NoConsultantGuard, NoOperatorGuardService],
    canLoad: [NoConsultantGuard, NoOperatorGuardService],
  },
  {
    path: 'map-view',
    loadChildren: () =>
      import('./feature/map-view/map-view.module').then((m) => m.MapViewModule),
    canActivate: [AuthGuard, NoConsultantGuard],
    canLoad: [NoConsultantGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./feature/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-authentication/reset-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'preview-report-for-client/:id',
    component: PreviewReportForClientComponent,
  },
  {
    path: 'control-panel',
    loadChildren: () =>
      import('./feature/control-panel/control-panel.module').then(
        (m) => m.ControlPanelModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
