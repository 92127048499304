import {Component} from '@angular/core';
import {DataService} from './core/service/data.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  userInfo: any;
  data: any;
  title = 'als-frontend';
  isAuthenticated: boolean = false;
  resetPass: boolean = false;

  constructor(private dataServ: DataService, public router: Router) {
    this.subscribeAuthenticationStatus();
    setTimeout(() => {
      this.dataServ._getUserInfo().subscribe((res) => {
        this.userInfo = res;
        this.data = JSON.parse(this.userInfo);
      });
    }, 500);
  }

  subscribeAuthenticationStatus(): void {
    setTimeout(() => {
      this.dataServ.isAuthenticated.subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      });

      this.dataServ.resetPass.subscribe((resetPass) => {
        this.resetPass = resetPass;
      });
    }, 500);
  }
}
