import {AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {
  FailureAnalysisManagementLocalService
} from '@app/feature/failure-analysis/failure-analysis-list/failure-analysis-management.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-fam-card',
  templateUrl: './fam-card.component.html',
  styleUrls: ['./fam-card.component.scss'],
})
export class FamCardComponent implements OnInit, AfterContentChecked {
  @Input() data: any = [];
  @Input() hasOptions: boolean = true;
  @Output() editEvent = new EventEmitter();
  @Output() infoEvent = new EventEmitter();
  @Output() deleteCompleted = new EventEmitter<any>();
  @Output() onExpandingComponentFolder = new EventEmitter();

  selectedFile;
  _Alive = true;
  treeData: TreeNode[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private famLocalService: FailureAnalysisManagementLocalService
  ) {
  }

  ngOnInit() {
    this.treeData = this.data.cardBody;
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  nodeSelect(event) {
    if (
      event &&
      event.node &&
      'type' in event.node &&
      event.node.type === 'component'
    ) {
      this.onExpandingComponentFolder.next(event.node);
    }
  }

  openEditModalComponent(pid) {
    const data = {
      id: pid,
      action: 'edit',
    };
    this.editEvent.emit(data);
  }

  deleteFolder(did: string, orgId: string) {
    let deleteId: any = [];
    deleteId.push(did);

    let deleteObj = {
      folderIdArray: deleteId,
      organizationId: orgId,
      folderType: 'Root',
    };

    this.deleteCompleted.emit(deleteObj);
  }

  openInfoModalComponent(pid) {
    const data = {
      id: pid,
      action: 'info',
    };
    this.infoEvent.emit(data);
  }

  save(id) {
    if (this.data.type !== 'User') {
      switch (this.data.type) {
        case 'Operator':
          this.famLocalService.nameOfSelectedOperator = this.data.title;
          this.famLocalService.nameOfSelectedWell = '';
          this.famLocalService.nameOfSelectedComponent = '';
          this.famLocalService.nameOfSelectedImage = '';
          this.famLocalService.organizationId = this.data.organizationId;
          break;
        case 'Well':
          this.famLocalService.nameOfSelectedWell = this.data.title;
          this.famLocalService.nameOfSelectedComponent = '';
          this.famLocalService.nameOfSelectedImage = '';
          break;
        case 'Component':
          this.famLocalService.nameOfSelectedComponent = this.data.title;
          this.famLocalService.nameOfSelectedImage = '';
          break;
        case 'Image':
          this.famLocalService.nameOfSelectedImage = this.data.title;
          break;
        case 'User':
          break;
      }
      this.router.navigate([id], {relativeTo: this.route});
    }
  }
}
