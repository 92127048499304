import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '@app/core/service/data.service';
import {ReportService} from '@app/core/service/report.service';
import {toastrMessages} from '@app/util/app-constants';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-preview-report-for-client',
  templateUrl: './preview-report-for-client.component.html',
  styleUrls: ['./preview-report-for-client.component.scss'],
})
export class PreviewReportForClientComponent implements OnInit {
  public userLogged: boolean = false;
  public urlPdf: string;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  private reportId: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly reportService: ReportService,
    private readonly toastrService: ToastrService,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.getReportId();
    this.checkAuth();
    this.getReportPreview();
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: Event): void {
    event.preventDefault();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public loginToApp(): void {
    this.router.navigate(['/user-authentication/login']);
  }

  private checkAuth(): void {
    if (this.dataService.getUserToken()) {
      this.router.navigate(['/reports', 'preview-report', this.reportId]);
      this.userLogged = true;
    } else {
      this.userLogged = false;
    }
  }

  private getReportId(): void {
    this.reportId = this.route.snapshot.paramMap.get('id');
  }

  private getReportPreview(): void {
    this.reportService
      .getPreviewReportForClient(this.reportId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.urlPdf = resp.url;
        },
        (err) => {
          this.toastrService.error(
            toastrMessages.errorMsg,
            toastrMessages.errorMsg,
            {
              timeOut: 3000,
            }
          );
        }
      );
  }
}
