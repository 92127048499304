export const USERINFOKEY = `userDetails`;
export const IMAGEKEY = `imageDetails`;
export const PASSWORDREGX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}$/;
export const EMAILREGX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const toastrMessages = {
  loginSuccess: 'Success',
  dismantle: 'Please select atleast one Dismantled',
  noRight: 'Request already send',
  noImage: 'No images on this component',
  noWell: 'No well found',
  reportSave: 'Report saved as draft',
  reportSaveReview: 'Report saved as To be reviewed.',
  reportSend: 'Report send as Successfully',
  reportApprove: 'Report approved',
  reportUnApprove: 'Report declined',
  errorReportUnApprove: 'Error with decline report',
  reportRejected: 'Report Rejected',
  selectClient: 'Please select a client',
  loginSuccessMsg: 'Welcome!',
  loginFail: 'Error',
  successMsg: 'Success',
  errorMsg: 'Error',
  accessDenied: 'Access Denied!',
  maximumImages: 'You are only allowed to upload a maximum of 10 files',
  maximumLimit: 'Maximum limit is 10',
  warning: 'Warning',
  info: 'Info',
  loginFailMsg: 'Invalid credentials...',
  passwordResetLinkSuccess:
    'Password reset link has been send to the entered mail id.',
  passwordResetLinkError: 'Password reset link not send.',
  confirmPasswordError: 'Passwords do not match.',
  changePasswordSuccess:
    'Password has been changed. Please login with the new credentials.',
  passwordChangeSuccess: 'Password has been changed successfully.',
  passwordChangeError: 'Unable to change password.',
  changePasswordError: 'Email not found.',
  userCreateSuccess: 'User created successfully',
  userCreateError: 'Error in User Management Operation.',
  userEmailExistsError: 'Email is already registered.',
  userDescriptionError: 'Equipment Description already exists',
  userEmailDeleted: 'User with this email-id is Deleted',
  reportDatacreatedSuccess: 'Report Data Created Successfully',
  reportDatacreatedError: 'Unable to create Report Data',
  reportDataUpdateSuccess: 'Report Data details updated.',
  reportDataUpdateError: 'Unable to update the Report Data',
  foldercreatedSuccess: 'Folder Created Successfully',
  folderUpdateSuccess: 'Folder details updated.',
  noMapAccessError:
    'You do not have permission to access maps. Please contact Admin',
  folderNoPermission:
    'You do not have permission to modify folders. Kindly contact Admin.',
  folderUpdateError: 'Unable to update the folder details',
  supplierListError: 'Unable to get the supplier list.',
  supplierCreateSuccess: 'Supplier Created Successfully',
  supplierDeleteSuccess: 'Supplier Deleted Successfully',
  supplierDeleteError: 'Unable to delete this supplier.',
  supplierUpdateSuccess: 'Supplier details Updated Successfully.',
  supplierUpdateError: 'Unable to update the supplier',
  userListError: 'Unable to get the user list.',
  userLoadError: 'Unable to load the user details.',
  userUpdateSuccess: 'User details Updated Successfully.',
  userUpdateError: 'Unable to update the user details.',
  forceLogoutsuccess: 'The user has been logged out successfully.',
  forceLogouterror: 'Unable to logout this user.',
  userActivatedSuccess: 'The user has been activated.',
  userDeactivateSuccess: 'The user has been deactivated.',
  userActivateError: 'Error during  activation/deactivation of the user.',
  userDeleteSuccess: 'User deleted successfully',
  userEmailVerified: 'Email has been verified successfully.',
  userAccess: 'Email has been sent',
  userAccessDenied: 'Email could not be sent',
  userEmailVerifiedErr: 'Unable to verify the email id.',
  userDeleteError: 'Unable to delete this user.',
  componentListError: 'Unable to load the component list.',
  componentReorderError: 'Unable to change the priority of the component.',
  componentDeleteSuccess: 'Component Deleted Successfully.',
  componentDeleteError: 'Unable to delete the component',
  componentEditSuccess: 'Component details updated.',
  componentEditError: 'Unable to edit this component.',
  componentCreateSuccess: 'Component created.',
  componentCreateError: 'Unable to create component.',
  componentExistingError: 'Component already exists.',

  rootCauseDeleteSuccess: 'Root Cause Of Failure Deleted Successfully.',
  rootCauseDeleteError: 'Unable to delete the Root Cause Of Failure',
  rootCauseEditSuccess: 'Root Cause Of Failure details Updated Successfully.',
  rootCauseEditError: 'Unable to edit this Root Cause Of Failure.',
  rootCauseCreateSuccess: 'Root Cause Of Failure created.',
  rootCauseCreateError: 'Unable to create Root Cause Of Failure.',
  rootCauseExistingError: 'Root Cause Of Failure already exists.',

  rootCauseDetailDeleteSuccess:
    'Root Cause Of Failure Detail Deleted Successfully.',
  rootCauseDetailDeleteError:
    'Unable to delete the Root Cause Of Failure Detail',
  rootCauseDetailEditSuccess:
    'Root Cause Of Failure Detail details Updated Successfully.',
  rootCauseEditDetailError: 'Unable to edit this Root Cause Of Failure Detail.',
  rootCauseDetailCreateSuccess: 'Root Cause Of Failure Detail created.',
  rootCauseDetailCreateError: 'Unable to create Root Cause Of Failure Detail.',
  rootCauseDetailExistingError: 'Root Cause Of Failure Detail already exists.',

  laggingFailureDetailsDeleteSuccess:
    'Lagging Failure Details Deleted Successfully.',
  laggingFailureDetailsDeleteError:
    'Unable to delete the Lagging Failure Details',
  laggingFailureDetailsEditSuccess:
    'Lagging Failure Details details Updated Successfully.',
  laggingFailureDetailsEditError:
    'Unable to edit this Lagging Failure Details.',
  laggingFailureDetailsCreateSuccess: 'Lagging Failure Details created.',
  laggingFailureDetailsCreateError: 'Unable to create Lagging Failure Details.',
  laggingFailureDetailsExistingError: 'Lagging Failure Details already exists.',

  laggingFailureGeneralDeleteSuccess:
    'Lagging Failure General Deleted Successfully.',
  laggingFailureGeneralDeleteError:
    'Unable to delete the Lagging Failure General',
  laggingFailureGeneralEditSuccess:
    'Lagging Failure General details Updated Successfully.',
  laggingFailureGeneralEditError:
    'Unable to edit this Lagging Failure General.',
  laggingFailureGeneralCreateSuccess: 'Lagging Failure General created.',
  laggingFailureGeneralCreateError: 'Unable to create Lagging Failure General.',
  laggingFailureGeneralExistingError: 'Lagging Failure General already exists.',

  reasonForPullDeleteSuccess: 'Reason for Pull Deleted Successfully.',
  reasonForPullDeleteError: 'Unable to delete the Reason for Pull',
  reasonForPullEditSuccess: 'Reason for Pull details Updated Successfully.',
  reasonForPullEditError: 'Unable to edit this Reason for Pull.',
  reasonForPullCreateSuccess: 'Reason for Pull created.',
  reasonForPullCreateError: 'Unable to create Reason for Pull.',
  reasonForPullExistingError: 'Reason for Pull already exists.',

  modeDeleteSuccess: 'Mode Of Failure deleted successfully.',
  modeDeleteError: 'Unable to delete the Mode Of Failure',
  modeDeleteReportError:
    'Unable to delete the Mode Of Failure,since it is used in a report',
  rootCauseDeleteReportError:
    'Unable to delete the Root Cause Of Failure,since it is used in a report',
  laggingFailureDeleteReportError:
    'Unable to delete the Lagging Failure,since it is used in a report',
  subComponentDeleteReportError:
    'Unable to delete the SubComponent,since it is used in a report',
  modeEditSuccess: 'Mode Of Failure details updated.',
  modeEditError: 'Unable to edit this Mode Of Failure.',
  modeCreateSuccess: 'Mode Of Failure created successfully.',
  modeCreateError: 'Unable to create Mode Of Failure.',
  modeExistingError: 'Mode Of Failure already exists.',
  subComponentExistingError:
    'Failed subcomponent already exists for this component',
  subComponentCreateSuccess: 'Failed subcomponent Created Successfully',
  subComponentEditSuccess: 'Failed subcomponent Edited Successfully',
  subComponentEditError: 'Unable to edit Failed subcomponent',
  subComponentDeleteSuccess: 'Failed subcomponent Deleted Successfully',
  subComponentDeleteError: 'Unable to delete Failed subcomponent',
  subComponentCreateError: 'Unable to create Failed SubComponent',
  folderCreateError: 'Unable to create Folder.',
  folderLocInvalid: 'Invalid location coordinates',
  folderDeletedError: 'Unable to delete Folder.',
  folderDeletedSuccess: 'Folder deleted successfully.',
  folderMappingExistsError: 'This user is already mapped',
  wellNoLongError: 'Well Number is too long.',
  serialNoLongError: 'Serial number is too long.',
  folderMarkSuccess: 'Successfully changed the status of the folder.',
  folderMarkError: 'Unable to change the status of the folder.',
  tagListingError: 'Unable to load the Tag list.',
  taggedImageListingError: 'Unable to list the images.',
  emptyTagIdError: 'Empty tagId Array received',
  tagDeleteSuccess: 'Tag has been deleted.',
  tagDeleteError: 'Couldn\'t delete,Tag is used with some images',
  tagCreateSuccess: 'Tag created successfully.',
  tagCreateError: 'Unable to create this Tag.',
  tagExistsError: 'Tag is already existing.',
  tagEditSuccess: 'Tag details updated successfully',
  taggedWellNotFound: 'There are no wells with the selected tag. ',
  tagEditError: 'Unable to edit the tag details',
  trashDeleteSuccess: 'File deleted Permanently.',
  trashDeleteError: 'Unable to delete the file.',
  trashRestoreSuccess: 'File has been restored.',
  trashRestoreError: 'Unable to restore the fie',
  equipmentSuccess: 'Equipment Description Created Successfully',
  equipmentError: 'Unable to create the equipment description',
  equipmentEditSuccess: 'Equipment Description Updated Successfully',
  equipmentExistingError: 'Equipment already exists',
  equipmentEditError: 'Unable to edit this equipment.',
  imageSuccess: 'Uploaded Successfully.',
  previewProgress: 'Loading Report Preview...',
  imageError: 'Error in Image Upload.',
  imageFormatError: 'This file format is not supported',
  imageDelete: 'Images deleted successfully.',
  imageDeleteError: 'Images cannot be deleted.',
  maximumOperatorError: 'Maximum operator limit is 10',
  minimumOperatorError: 'Select atleast one operator!',
  deleteTag: 'Tag deleted successfully',
  duplicateTagError: 'Tag already exists',
  tagSucces: 'Tag added successfully',
  imageUploadWarning: 'Please Select Client and Well before Uploading Image',
  failedSubComponentFilterError:
    'Unable to filter. Please chose Failed Component',
  errorEmailGeneratedAboutReport: 'Error with generating email to client',
  emailGeneratedAboutReport: 'Email generated to client',
  errorWithApploadingAttachedFile: 'Error with apploading attached file',
  fileSuccessfullyAttached: 'File successfully attached',
  errorWithRemovingAttachedFile: 'Error with removing attached file',
  fileSuccessfullyRemoved: 'File successfully removed',
  errorWithDownloadingFile: 'Error with downloading file',
  fileSuccessfullyDownloaded: 'File successfully downloaded',
  errorWithGettingDataForExport: 'Error with getting data for export',
  errorWithGettingFile: 'Error with getting file',
  wrongFileExtension: 'Wrong file extension',
};

export class AppConstant {
}
