import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {USERINFOKEY} from '@app/util/app-constants';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public authenticatedUserInfoSubject = new BehaviorSubject(null);
  public isAuthenticatedSubject = new BehaviorSubject(false);
  public resetPassSubject = new BehaviorSubject(false);
  public authenticatedUserInfo =
    this.authenticatedUserInfoSubject.asObservable();
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  public resetPass = this.resetPassSubject.asObservable();
  userDetails: any;
  imageDetails;
  private userSubject = new BehaviorSubject<any>(null);
  public currentUser = this.userSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  private userRoleString$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private _injector: Injector) {
  }

  private get _router() {
    return this._injector.get(Router);
  }

  _setUserInfo(value) {
    if (typeof value === 'object') {
      this.userDetails = JSON.stringify(value);
    } else {
      this.userDetails = value;
    }

    this.http
      .get(`userDetailsViews/${JSON.parse(this.userDetails).userId}`)
      .pipe(take(1))
      .subscribe((x: any) => {
        this.imageDetails = x.PreviewImageData && x.PreviewImageData[0];
        localStorage.setItem('IMAGEKEYS', JSON.stringify(this.imageDetails ? this.imageDetails : null));
        if (x.roleName !== 'admin') {
          this._router.navigate(['/dashboard']);
        } else {
          this._router.navigate(['/dashboard']);
        }
        this.setUserRole(x.roleName);
      });

    this.userSubject.next(this.userDetails);
    localStorage.setItem(USERINFOKEY, this.userDetails);
  }

  setUserDetails() {
    this.populateUserInfoSubject();
    let value = localStorage.getItem(USERINFOKEY);
    if (typeof value === 'object' && value !== null) {
      this.userDetails = JSON.stringify(value);
    } else {
      this.userDetails = value;
    }
    this.userSubject.next(this.userDetails);
  }

  clearHolders() {
    localStorage.clear();
    this.authenticatedUserInfoSubject.next(null);
    this.isAuthenticatedSubject.next(false);
  }

  getUserRole() {
    return this.userRoleString$
      .asObservable()
      .pipe(map((x) => (x ? x : localStorage.getItem('ROLE'))));
  }

  setUserRole(roleString) {
    localStorage.setItem('ROLE', roleString);
    this.userRoleString$.next(roleString);
  }

  populateUserInfoSubject() {
    const userInfo = localStorage.getItem('accessToken');

    if (userInfo !== undefined && userInfo != null) {
      this.isAuthenticatedSubject.next(true);
      this.authenticatedUserInfoSubject.next(userInfo);
    }
  }

  /**
   * @author Praveen Prasad
   * @description return user details
   * @returns {Observable<boolean>}
   * @memberof DataService
   */
  _getUserInfo(): Observable<boolean> {
    return this.currentUser;
  }

  /**
   * @author Praveen Prasad
   * @description To unsubscribe the user subject during logout
   * @memberof DataService
   */
  manageLogout() {
    this.clearHolders();
    this.userSubject.next(false);
  }

  /**
   * @author Praveen Prasad
   * @description data initializing during app component load
   * @memberof DataService
   */
  loadUserInfo() {
    if (localStorage.getItem(USERINFOKEY)) {
      this._setUserInfo(localStorage.getItem(USERINFOKEY));
    }
  }

  /**
   * @author Praveen Prasad
   * @description return the access token
   * @returns
   * @memberof DataService
   */
  getUserToken() {
    return localStorage.getItem('accessToken');
  }

  /**
   * @author Praveen Prasad
   * @description set the access token in local storage
   * @param {string} token
   * @memberof DataService
   */
  setUserToken(token: string) {
    localStorage.setItem('accessToken', token);
    this.populateUserInfoSubject();
  }

  /**
   * @author Praveen Prasad
   * @description remove the token from local storage
   * @memberof DataService
   */
  purgeAuth() {
    localStorage.removeItem(USERINFOKEY);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('ROLE');
    localStorage.clear();
  }

  // Function to check whether the token is expired or not
  checkTokenExpiry() {
    const helper = new JwtHelperService();
    return helper.isTokenExpired(this.getUserToken());
  }

  resetPassDisplay() {
    this.resetPassSubject.next(true);
  }

  resetPassNoDisplay() {
    this.resetPassSubject.next(false);
  }
}
