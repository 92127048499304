import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FailureAnalysisManagementLocalService {
  _nameOfSelectedOperator: string;

  get nameOfSelectedOperator() {
    return this._nameOfSelectedOperator
      ? this._nameOfSelectedOperator
      : localStorage.getItem('_nameOfSelectedOperator');
  }

  set nameOfSelectedOperator(val) {
    this._nameOfSelectedOperator = val;
    localStorage.setItem('_nameOfSelectedOperator', val);
  }

  _nameOfSelectedWell: string;

  get nameOfSelectedWell() {
    return this._nameOfSelectedWell
      ? this._nameOfSelectedWell
      : localStorage.getItem('_nameOfSelectedWell');
  }

  set nameOfSelectedWell(val) {
    this._nameOfSelectedWell = val;
    localStorage.setItem('_nameOfSelectedWell', val);
  }

  _nameOfSelectedComponent: string;

  get nameOfSelectedComponent() {
    return this._nameOfSelectedComponent
      ? this._nameOfSelectedComponent
      : localStorage.getItem('_nameOfSelectedComponent');
  }

  set nameOfSelectedComponent(val) {
    this._nameOfSelectedComponent = val;
    localStorage.setItem('_nameOfSelectedComponent', val);
  }

  _nameOfSelectedImage: string;

  get nameOfSelectedImage() {
    return this._nameOfSelectedImage
      ? this._nameOfSelectedImage
      : localStorage.getItem('_nameOfSelectedImage');
  }

  set nameOfSelectedImage(val) {
    this._nameOfSelectedImage = val;
    localStorage.setItem('_nameOfSelectedImage', val);
  }

  _organizationId: string;

  get organizationId() {
    return this._organizationId
      ? this._organizationId
      : localStorage.getItem('_organizationId');
  }

  set organizationId(val) {
    this._organizationId = val;
    localStorage.setItem('_organizationId', val);
  }

  _userId: string;

  get userId() {
    return this._userId ? this._userId : localStorage.getItem('_userId');
  }

  set userId(val) {
    this._userId = val;
    localStorage.setItem('_userId', val);
  }
}
