import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loadingStatus: Subject<any> = new Subject<any>();
  private loadings = false;

  constructor() {
  }

  get loading(): boolean {
    return this.loadings;
  }

  set loading(value: boolean) {
    this.loadings = value;
    this.loadingStatus.next(value);
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }
}
