import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlightSearchTerm',
})
export class HighlightSearchTermPipe implements PipeTransform {
  public transform(text: string, searchTerm: string): string {
    if (!text || !searchTerm) {
      return text;
    }

    const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedSearchTerm, 'gi');
    const highlightedText = text.replace(
      regex,
      '<span class="highlightText">$&</span>'
    );

    return highlightedText;
  }
}
