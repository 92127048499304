import {Injectable} from '@angular/core';
import {DataService} from '@app/core/service/data.service';
import {CanActivate, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private dataService: DataService, private router: Router) {
  }

  canActivate(): boolean {
    if (!this.dataService.getUserToken()) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}
