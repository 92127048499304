import {FamCardComponent} from './fam-card/fam-card.component';
import {CardModule} from 'primeng/card';
import {TreeModule} from 'primeng/tree';
import {ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from '@app/shared/header/header.component';
import {LoaderComponent} from '@app/shared/loader/loader.component';
import {SidebarComponent} from '@app/shared/sidebar/sidebar.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {FooterComponent} from './footer/footer.component';
import {SidebarModule} from 'primeng/sidebar';
import {MenubarModule} from 'primeng/menubar';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ChangePasswordComponent} from '@app/shared/change-password/change-password.component';
import {GeoLocationDirective} from '@app/core/directives/geoloc.directive';
import {DialogModule} from 'primeng/dialog';
import {CreateReportDialogComponent} from './dialogs/create-report-dialog/create-report-dialog.component';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {CoreModule} from '@app/core/core.module';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import { DeleteConfirmationDialogComponent } from './dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component';
import {NgxEchartsModule} from 'ngx-echarts';


@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    LoaderComponent,
    FooterComponent,
    FamCardComponent,
    GeoLocationDirective,
    ChangePasswordComponent,
    CreateReportDialogComponent,
    DeleteConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    TreeModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    SidebarModule,
    MenubarModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    CoreModule,
    DropdownModule,
    CalendarModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    LoaderComponent,
    FooterComponent,
    FamCardComponent,
    BsDropdownModule,
    ChangePasswordComponent,
    ModalModule,
  ],
})
export class SharedModule {
}
