import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent {
  name: string;
  constructor( private readonly dynamicDialogConfig: DynamicDialogConfig,
               private readonly ref: DynamicDialogRef
             ) {
    this.name = dynamicDialogConfig.data;
  }

  onSubmit(): void {
    this.ref.close(true);
  }

  cancel(): void {
    this.ref.close();
  }
}
