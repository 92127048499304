import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '@app/core/service/data.service';
import {Router} from '@angular/router';
import {UserManagementService} from '@app/core/service/user-management.service';
import {takeWhile} from 'rxjs/operators';
import {SidebarService} from '@app/core/service/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  accessToken: string;
  _Alive = true;
  userDetails: any;
  userName: string;
  role: string;
  name: string;
  logoutModal = false;

  constructor(
    private dataService: DataService,
    private router: Router,
    private userService: UserManagementService,
    private sidebarService: SidebarService
  ) {
  }

  ngOnInit() {
    this.getUserDetails();
  }

  ngOnDestroy() {
    this._Alive = false;
  }

  getUserDetails() {
    if (localStorage.getItem('userDetails')) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.userService.getUserDetails(this.userDetails.userId, '').subscribe(
        (resp) => {
          localStorage.setItem('User', JSON.stringify(resp));
          this.name = resp.name || resp.roleName || '';
          this.dataService.getUserRole().subscribe((x) => {
            this.role = x;
            if (this.role === 'admin') {
              this.userName = 'Admin';
            } else if (this.role === 'operator') {
              this.userName = this.name || 'Operator';
            } else if (this.role === 'consultant') {
              this.userName = this.name || 'Consultant';
            } else if (this.role === 'esp supplier') {
              this.userName = this.name || 'ESP Supplier';
            }
          });
        },
        (err) => {
        }
      );
    }
  }

  navDashboard() {
    this.sidebarService.setLogoClick(true);
    this.router.navigate(['/dashboard']);
  }

  userLogout() {
    this.logoutModal = true;
  }

  logoutUser() {
    this.logoutModal = false;
    this.userService
      .logout()
      .pipe(takeWhile(() => this._Alive))
      .subscribe(
        (resp) => {
          this.dataService.purgeAuth();
          this.dataService.manageLogout();
          this.router.navigate(['/user-authentication/login']);
        },
        (err) => {
        }
      );
  }
}
