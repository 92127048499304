import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {ReportService} from '@app/core/service/report.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-create-report-dialog',
  templateUrl: './create-report-dialog.component.html',
  styleUrls: ['./create-report-dialog.component.scss']
})
export class CreateReportDialogComponent implements OnInit, OnDestroy {
  filterSearch: string;
  reportForm: FormGroup;
  clientList = [];
  isEdit = false;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly ref: DynamicDialogRef,
              private readonly reportService: ReportService,
              private readonly dynamicDialogConfig: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.isEdit = this.dynamicDialogConfig.data.report?.report_data;
    this.setForm();
    this.getOperator();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  cancel(): void {
    this.ref.close();
  }

  filterHighlightSearch(event: {
    originalEvent: Event;
    filter: string;
  }): void {
    this.filterSearch = event.filter;
  }

  onFocus(): void {
    this.filterSearch = null;
  }

  onSubmit(): void {
    const selectedClient = this.clientList.find(client => client.id === this.reportForm.value.client);

    const model = {
      ...this.reportForm.value,
      clientId: selectedClient?.id,
      client: selectedClient?.name,
      organizationId: selectedClient?.organizationId
    };
    if (this.dynamicDialogConfig.data?.report) {
      model.reportId = this.dynamicDialogConfig.data.report.reportId;
      model.type = this.dynamicDialogConfig.data.report.type;
    }

    this.ref.close(model);
  }

  private getOperator(): void {
    const role = localStorage.getItem('ROLE');
    this.reportService
      .getOperator(role, this.dynamicDialogConfig.data.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          const operatorData = role === 'admin' ? 'data' : 'Data';
          this.clientList = resp?.[operatorData].map((data) => {
            return {
              ...data,
              label: data.name,
              value: data.id,
            };
          });
        });
  }


  private setForm(): void {
    let title = null;
    let inspectionDate = null;
    let client = null;
    let consultingRep = null;
    if (this.dynamicDialogConfig.data.report?.report_data) {
      title = this.dynamicDialogConfig.data.report.report_data.incidentDetails.reportHeader;
      inspectionDate = new Date(this.dynamicDialogConfig.data.report.report_data.incidentDetails.runInfo[0].inspectionDate);
      client = this.dynamicDialogConfig.data.report.report_data.incidentDetails?.runInfo[0].clientId;
      consultingRep = this.dynamicDialogConfig.data.report.report_data.incidentDetails?.runInfo[0].consultingRep;
    }

    this.reportForm = new FormGroup({
      reportTitle: new FormControl(title, Validators.required),
      inspectionDate: new FormControl(inspectionDate, Validators.required),
      client: new FormControl(client, Validators.required),
      consultingRep: new FormControl(consultingRep, Validators.required),
    });
  }
}
