import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private http: HttpClient) {
  }

  getUserType() {
    if (localStorage.getItem('USER_TYPE')) {
      return localStorage.getItem('USER_TYPE');
    }
  }

  setUserType(userType: string) {
    localStorage.setItem('USER_TYPE', userType);
  }

  getUserId() {
    if (localStorage.getItem('user_id')) {
      return localStorage.getItem('user_id');
    }
  }

  setUserId(id: string) {
    localStorage.setItem('user_id', id);
  }

  removeUserId() {
    if (localStorage.getItem('user_id')) {
      localStorage.removeItem('user_id');
    }
  }

  removeUserType() {
    if (localStorage.getItem('USER_TYPE')) {
      localStorage.removeItem('USER_TYPE');
    }
  }

  verifyUser(userId: string, token: string) {
    return this.http.get(`users/confirm?uid=${userId}&access_token=${token}`);
  }

  getUsersList(
    userRole: string,
    limit?: number,
    skip?: number,
    search?: string
  ): Observable<any> {
    if (userRole === 'operator') {
      return this.http.get(
        `userDetailsViews/get-user-listing?role=${userRole}&limit=${limit}&skip=${skip}${
          search ? `&search=${search}` : ''
        }`
      );
    } else {
      return this.http.get(
        `userDetailsViews/get-user-listing?role=${userRole}&limit=${limit}&skip=${skip}${
          search ? `&search=${search}` : ''
        }`
      );
    }
  }

  getUsersListDropdown(userRole: string) {
    return this.http.get(`userDetailsViews/get-user-listing?role=${userRole}`);
  }

  getAssignedUsersList(orgId: string): Observable<any> {
    return this.http.get(
      `organizationUserMappings/organization-mapped-users/${orgId}`
    );
  }

  createUser(userData: any, role: string) {
    if (role === 'Operator') {
      return this.http.post(`organizations`, userData);
    } else {
      return this.http.post(`users`, userData);
    }
  }

  getSupplierList(limit?: number, skip?: number, search?: string) {
    let filter = {
      where: {isDeleted: 'false'},
      search: '%m%',
      limit: '10',
      skip: '0',
      'order By': 'desc',
    };
    let filterParams = JSON.stringify(filter);
    return this.http.get(
      `suppliers/supplier-listing?limit=${limit}&skip=${skip}&orderBy=desc${
        search ? `&search=${search}` : ''
      }`
    );
  }

  createSupplier(suppierData: any, role: string) {
    return this.http.post(`suppliers`, suppierData);
  }

  updateSupplier(suppierData: any, role: string) {
    return this.http.patch(`suppliers`, suppierData);
  }

  getSupplierDetails(id: string) {
    let filter = {where: {id: `${id}`}};
    let filterParams = JSON.stringify(filter);
    return this.http.get(`suppliers?filter=${filterParams}`);
  }

  logout() {
    return this.http.post(`users/logout`, {});
  }

  getUserDetails(userId: string, role: string): Observable<any> {
    if (role === 'Operator') {
      return this.http.get(`organizations/${userId}`);
    } else {
      return this.http.get(`userDetailsViews/${userId}`);
    }
  }

  updateUserDetails(userData: any, userId: string, role: string) {
    if (role === 'Operator') {
      return this.http.patch(`organizations`, userData);
    } else {
      return this.http.post(`users/edit-user-details/${userId}`, userData);
    }
  }

  getUserFolders(id: string) {
    return this.http.get(`folderListingViews/user-folder-listing/${id}`);
  }

  changeUserPassword(passwordData: any) {
    return this.http.post(`users/change-password`, passwordData);
  }

  forceLogoutUser(userId: string, token: string) {
    return this.http.get(`users/forced-logout/${userId}`);
  }

  deactivateUser(userData: any, token: string) {
    return this.http.post(`users/user-deactivate`, userData);
  }

  verifyMail(userId: string, emailObj: any) {
    return this.http.patch(`users/verify-account-email/${userId}`, emailObj);
  }

  deleteUser(userId: string, role: string) {
    return this.http.delete(`users/delete/${userId}?role=${role}`);
  }

  deleteSupplier(supplierId: string) {
    return this.http.delete(`suppliers/${supplierId}`);
  }

  deleteMultipleSuppliers(deletedSuppliers: any) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: deletedSuppliers,
    };
    return this.http.delete(`suppliers/delete-muti-suppliers`, httpOptions);
  }

  getEquipmentList(limit?: number) {
    return this.http.get(
      `equipmentDescriptions/equipment-description-listing?limit=${limit}`
    );
  }
}
