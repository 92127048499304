<div [formGroup]="reportForm" class="report-wrapper pt-4 pb-4 pr-4">
  <div class="pb-4">
    <label class="reports-custom-label" for="reportTitle">Report Title</label>
    <input
      [ngClass]="{'is-invalid': reportForm.controls['reportTitle'].touched && reportForm.controls['reportTitle'].errors}"
      autocomplete="off"
      class="w-100 cus-input"
      formControlName="reportTitle"
      id="reportTitle"
      maxlength="50"
      pInputText
      placeholder="Report Title"
      type="text"
    />
    <div *ngIf="reportForm.controls['reportTitle'].touched && reportForm.controls['reportTitle'].errors"
         class="invalid-feedback">
      <div *ngIf="reportForm.controls['reportTitle'].errors['required']">
        This field is mandatory
      </div>
    </div>
  </div>

  <div class="w-100 pb-4">
    <label class="reports-custom-label" for="client">Client</label>

    <p-dropdown
      (onFilter)="filterHighlightSearch($event)"
      (onFocus)="onFocus()"
      [ngClass]="{'is-invalid': reportForm.controls['client'].touched && reportForm.controls['client'].errors}"
      [options]="clientList"
      [showClear]="true"
      [styleClass]="'w-100 drop-loc drop-icon-arrow'"
      appendTo="body"
      autoWidth="false"
      class="d-flex border-gray border w-100"
      filter="true"
      formControlName="client"
      id="client"
      placeholder="Client"
    >
      <ng-template let-option pTemplate="item">
        <div
          [innerHTML]="option.label | highlightSearchTerm : filterSearch"
        ></div>
      </ng-template>
    </p-dropdown>
    <div
      *ngIf=" reportForm.controls['client'].touched && reportForm.controls['client'].errors"
      class="invalid-feedback">

      <div *ngIf="reportForm.controls['client'].errors['required']">
        This field is mandatory
      </div>
    </div>
  </div>

  <div
    (click)="inspectionDateCal.showOverlay()"
    class="w-100">
    <label class="reports-custom-label" for="inspectionDate">Inspection Date</label>

    <p-calendar
      #inspectionDateCal
      [dateFormat]="'dd-mm-yy'"
      [inputStyleClass]="'cursor-pointer calender-area-style cus-input'"
      [monthNavigator]="true"
      [ngClass]="{'is-invalid': reportForm.controls['inspectionDate'].touched && reportForm.controls['inspectionDate'].errors }"
      [placeholder]="'Inspection Date'"
      [styleClass]="'calender-style mb-2 '"
      [yearNavigator]="true"
      appendTo="body"
      class="d-flex h-42"
      formControlName="inspectionDate"
      id="inspectionDate"
      yearRange="2000:2050">
    </p-calendar>
    <div
      *ngIf="reportForm.controls['inspectionDate'].touched && reportForm.controls['inspectionDate'].errors"
      class="invalid-feedback">
      <div *ngIf="reportForm.controls['inspectionDate'].errors['required']">
        This field is mandatory
      </div>
    </div>
  </div>

  <div class="w-100 pb-4">
    <label
      class="reports-custom-label"
      for="consultingRep"
    >Consultant</label>
    <input
      [ngClass]="{'is-invalid': reportForm.controls?.['consultingRep'].errors}"
      autocomplete="off"
      class="w-100 cus-input"
      formControlName="consultingRep"
      id="consultingRep"
      pInputText
      placeholder="Dwiggins Consulting Representative"
      type="text"
    />
    <div *ngIf="reportForm.controls['consultingRep'].touched && reportForm.controls?.['consultingRep'].errors"
      class="invalid-feedback">
      <div *ngIf="reportForm.controls?.['consultingRep'].errors['required']">
        This field is mandatory
      </div>
    </div>
  </div>
</div>


<div class="pl-3 pr-3 pb-3">
  <button
    (click)="cancel()"
    class="cursor-pointer cancel cus-input btn-white shadow-none"
    pButton
    type="button">
    Cancel
  </button>

  <button
    (click)="onSubmit()"
    [disabled]="reportForm.invalid"
    class="not-allowed btn-red mr-3"
    pButton
    type="button">
    {{isEdit ? 'Edit' : 'Create'}}
  </button>
</div>
