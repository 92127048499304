import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DownloadFileInterface} from '../Interfaces/reports/download-file.interface';
import {AttachedFileInterface} from '../Interfaces/reports/attached-file.interface';
import {InfoToEmailClientInterface} from '../Interfaces/reports/info-to-email-client.interface';
import {DataForEmailClientInterface} from '../Interfaces/reports/data-for-email-client.interface';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {
  }

  public getReportDetails(
    status,
    limit?: number,
    skip?: number,
    search?: string,
    operators?: any,
    orderBy?: string,
    inspectionDay?: any,
    appTab?: boolean
  ): Observable<any> {
    let params = new HttpParams();
    if (operators) {
      for (const key of Object.keys(operators)) {
        if (!!operators[key as keyof typeof operators]) {
          params = params.append(key, operators[key as keyof typeof operators]);
        }
      }
    }
    if (appTab) {
      params = params.append('appTab', appTab);
    }

    return this.http.get(
      `reports/list-repots?status=${status}&limit=${limit}${
        search ? `&search=${search}` : ''
      }&skip=${skip}  ${
        orderBy ? `&orderBy=${orderBy}` : ''
      }&inspectionDay=${inspectionDay}`,
      {params}
    );
  }

  public getReport(id): Observable<any> {
    return this.http.get(`reports/${id}`);
  }

  public getOperator(role, userId): Observable<any> {
    if (role === 'consultant') {
      return this.http.get(
        `folders/${userId}/list-root-folders?limit=${100}&skip=${0}`
      );
    } else {
      return this.http.get(
        `folders/list-admin-root-folders?limit=${100}&skip=${0}`
      );
    }
  }

  public submitAsDraft(data, type): Observable<any> {
    return this.http.post(`reports/create-report?status=${type}`, data);
  }

  public uploadImage(fileData: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data; charset=utf-8');

    return this.http.post(`files/upload-image`, fileData, {headers: headers});
  }

  public submitAsDraftAsEdit(
    data: any,
    type: string | number | boolean,
    id: string | number | boolean,
    isChangedByAdmin: boolean = false,
    isConsultantReportApproval: boolean = false
  ): Observable<any> {
    let params = new HttpParams()
      .append('reportId', id)
      .append('isChanged', isChangedByAdmin)
      .append('status', type);


    if (isConsultantReportApproval && type === 'Draft') {
      params = params.delete('status');
    }

    return this.http.post(
      `reports/create-report`, data, {params}
    );
  }

  public generateReport(id): Observable<any> {
    return this.http.get(`reports/generate-report?incidentId=${id}`);
  }

  public submitAsPreview(id): Observable<any> {
    return this.http.get(`reports/preview-report?incidentId=${id}`);
  }

  public getPreviewReportForClient(id: string): Observable<any> {
    return this.http.get(`report-for-client/${id}`);
  }

  public getWell(role, id, organizationId): Observable<any> {
    if (role === 'consultant') {
      return this.http.get(
        `folderListingViews/user-wellFolder-listing/${organizationId}?parentId=${id}&skip=0&limit=1000`
      );
    } else {
      return this.http.get(
        `folders/list-admin-fam-well-folders?parentId=${id}&skip=0&limit=1000`
      );
    }
  }

  public deleteReportDetails(reportId): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reportId,
    };

    return this.http.delete(`reports/delete-report`, options);
  }

  public unApproveReport(reportId: string, reason: string): Observable<void> {
    return this.http.post<void>(`un-approve-report/${reportId}`, {reason});
  }

  public getInfoToEmail(
    reportId: string
  ): Observable<InfoToEmailClientInterface> {
    return this.http.get<InfoToEmailClientInterface>(
      `operator-email/${reportId}`
    );
  }

  public generateEmailToClientAboutReport(
    reportId: string,
    data: DataForEmailClientInterface
  ): Observable<void> {
    return this.http.post<void>(`email-report/${reportId}`, {data});
  }

  public getAttachedFilesToReport(
    reportId: string
  ): Observable<AttachedFileInterface[]> {
    return this.http.get<AttachedFileInterface[]>(
      `report-attachments/${reportId}`
    );
  }

  public uploadFileToReport(reportId: string, fileData: any): Observable<void> {
    return this.http.post<void>(
      `report-attachments/upload/${reportId}`,
      fileData
    );
  }

  public removeFileFromReport(
    fileId: string,
    fileName: string
  ): Observable<void> {
    return this.http.delete<void>(
      `report-attachments/delete/${fileId}/${fileName}`
    );
  }

  public downloadFileFromAttachments(
    fileName: string
  ): Observable<DownloadFileInterface> {
    return this.http.get<DownloadFileInterface>(
      `report-attachments/download/${fileName}`
    );
  }

  public plainText(reportId: string): Observable<Blob> {
    return this.http.get<Blob>(`report-plain-text/${reportId}`, {
      responseType: 'blob' as 'json',
    });
  }

  public downloadReport(pdfName: string): Observable<ArrayBuffer> {
    return this.http.get(`download-pdf/${pdfName}`, {
      responseType: 'arraybuffer',
    });
  }
}
