/** Angular imports used in the application */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient) {
  }

  /**
   *
   * @description authenticate the user
   * @param {*} loginData
   * @returns
   * @memberof AuthService
   */
  userAuth(loginData): Observable<any> {
    return this.httpClient
      .post<any>(`users/login`, loginData)
      .pipe(catchError(this.formatErrors));
  }

  /**
   * @author Praveen Prasad
   * @description API call to reset password
   * @param {string} newPassword
   * @returns
   * @memberof AuthService
   */
  resetPassword(newPassword: any, token: any) {
    return this.httpClient.post(
      `users/reset-password?access_token=${token}`,
      newPassword
    );
  }

  sendVerificationMail(email) {
    return this.httpClient.post(`users/reset`, email);
  }

  loginAccess(data) {
    return this.httpClient.post(`users/request-login`, data);
  }

  /**
   * Request Error handling block
   */
  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
