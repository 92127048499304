<!-- BEGIN SIDE MENU EXPANDED -->
<p-sidebar
  [autoZIndex]="true"
  [modal]="false"
  [visible]="visible"
  styleClass="sidebar-width"
  position="left">
  <div class="side-bar">
    <div
      [ngClass]="{ show: navbarCollapse, hide: !navbarCollapse }"
      class="side-menu-expanded side-menu-left-wrapper"
      id="expanded-navbar"
    >
      <accordion [closeOthers]="oneAtATime" class="side-menu-left">
        <!-- [closeOthers]="oneAtATime"-->

        <accordion-group
          #fail="routerLinkActive"
          (click)="addId($event, i)"
          *ngFor="let menuItem of menuItemList; let i = index"
          [routerLinkActive]="[menuItem.linkActive]"
          class="side-menu-left-item side-menu-item-single"
        >
          <!--(isOpenChange)="log($event)"-->
          <!--[isOpen]="menuItem.isOpen"-->
          <button
            [routerLink]="[menuItem.link]"
            accordion-heading
            class="btn btn-link btn-blocked clearfix"
            type="button"
          >
            <span class="side-menu-left-heading">
              <span class="side-menu-img-styles">
                <img
                  [src]="
                    'assets/images/' +
                    ((menuItem?.hasOwnProperty('active') && menuItem.active) ==
                    true
                      ? menuItem.img + '-active'
                      : menuItem.img) +
                    '.svg'
                  "
                  [title]="menuItem.alt"
                  class="w-15 material-icons"
                /></span>
              <span class="side-menu-left-label">{{ menuItem.title }}</span>
            </span>
          </button>

          <!-- Sub categories List -->
          <ng-container *ngIf="menuItem.isOpen">
            <ul class="sidebar-content-allign">
              <li
                (click)="
                  checkRoute($event, subCategory.link, subCategory.route, i, j)
                "
                *ngFor="let subCategory of menuItem.children; let j = index"
                [ngClass]="
                  subCategory.hasOwnProperty('active') && subCategory.active
                    ? 'active-child'
                    : ''
                "
                [routerLinkActive]="[subCategory.linkActive]"
                class="sidebar-options no-margin no-outline"
              >
                {{ subCategory.title }}
              </li>
            </ul>
          </ng-container>
          <!-- End of Sub categories List -->
        </accordion-group>
      </accordion>
    </div>
  </div>
</p-sidebar>
