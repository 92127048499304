import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TrashbinService {
  constructor(private http: HttpClient) {
  }

  getTrashDataList(limit: number, skip: number, search: string) {
    return this.http.get(
      `folders/list-recycle-bin-folders?limit=${limit}&skip=${skip}${
        search ? `&search=${search}` : ''
      }`
    );
  }

  deleteFromTrash(deleteArray: any) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: deleteArray,
    };
    return this.http.delete(`folders/trash-destroy`, httpOptions);
  }

  restoreFromTrash(restoreArray: any) {
    return this.http.post(`folders/trash-restore`, restoreArray);
  }
}
