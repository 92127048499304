<div class="offst-stl">
  <div class="container">
    <div id="formWrapper">
      <div id="form">
        <div class="logo">
          <img class="img-size" src="assets/images/DCL-logo-text.png"/>
        </div>
        <form [formGroup]="changePassForm">
          <label class="form-label">New Password</label>
          <div class="form-item">
            <input
              [type]="visiblePassword ? 'text' : 'password'"
              autocomplete="off"
              class="form-style"
              formControlName="password"
              id="password"
              maxlength="12"
              name="password"
              placeholder="New Password"
            />
            <img
              (click)="visiblePassword = !visiblePassword"
              *ngIf="visiblePassword"
              class="cursor-pointer"
              src="assets/images/eye.svg"
            />
            <img
              (click)="visiblePassword = !visiblePassword"
              *ngIf="!visiblePassword"
              class="cursor-pointer"
              src="assets/images/hide-password.svg"
            />
          </div>

          <label class="form-label">Confirm Password</label>
          <div class="form-item">
            <input
              [type]="visiblePassword2 ? 'text' : 'password'"
              autocomplete="off"
              class="form-style"
              formControlName="cpassword"
              id="cpassword"
              maxlength="12"
              name="cpassword"
              placeholder="Confirm Password"
            />
            <img
              (click)="visiblePassword2 = !visiblePassword2"
              *ngIf="visiblePassword2"
              class="cursor-pointer"
              src="assets/images/eye.svg"
            />
            <img
              (click)="visiblePassword2 = !visiblePassword2"
              *ngIf="!visiblePassword2"
              class="cursor-pointer"
              src="assets/images/hide-password.svg"
            />
          </div>

          <div
            *ngIf="
              !changePassForm.controls.cpassword.valid &&
              changePassForm.get('cpassword').hasError('notEquivalent') ==
                true &&
              !changePassForm.get('cpassword').pristine
            "
            class="form-item"
          >
            <p class="password-err-msg">Passwords do not match</p>
          </div>

          <div class="form-item">
            <p class="password-notes">
              Note: The password must include at least one upper case letter, at
              least one lowercase letter, at least one digit and password length
              should be between 8 and 12
            </p>
          </div>

          <div class="form-item">
            <input
              (click)="onSubmitPass()"
              [disabled]="!changePassForm.valid"
              class="login form-label submit-btn"
              type="submit"
              value="Change Password"
            />
            <div class="clear-fix"></div>
          </div>
        </form>

        <div class="form-footer">
          <p class="text-center">Our International Company</p>
          <img class="als-logo" src="assets/images/ALS-app-logo.png"/>
        </div>
      </div>
    </div>
  </div>
</div>
